<template>
  <div
    v-if="richText"
    :class="$style.root"
  >
    <core-text-component
      :string="richText"
    />

    <core-text-component
      v-if="richTextTruncated && isTruncated"
      :string="richTextTruncated"
      :class="$style.textTruncated"
    />

    <layout-animated-underline-component
      v-if="richTextTruncated && !isTruncated"
      :class="$style.button"
      @click="isTruncated = true"
      tabindex="0"
      component="button"
      :inverted="true"
    >
      <template #default>
        {{ $t('shared.readMore') }}
      </template>
    </layout-animated-underline-component>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  richText: {
    type: String,
    required: true
  },
  richTextTruncated: {
    type: String,
    required: false
  }
})

const isTruncated = ref(false)
</script>

<style module>
.root {
}

.textTruncated {
  margin-top: var(--lh, 1em);
}

.button {
  composes: reset-button font-size-small from global;
}
</style>
